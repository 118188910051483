import React from 'react';
import {
  email,
  required,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin';
import GooglePlacesAutocomplete from '../../molecules/GooglePlacesAutocomplete';

function UserForm() {
  const t = useTranslate();
  return (
    <SimpleForm>
      <div>
        <TextInput
          source="firstName"
          name="firstName"
          fullWidth
          label={t('labels.firstName')}
          validate={[required()]}
        />
        <TextInput
          source="lastName"
          name="lastName"
          fullWidth
          label={t('labels.lastName')}
          validate={[required()]}
        />
        <TextInput
          source="email"
          name="email"
          type="email"
          fullWidth
          label={t('labels.email')}
          validate={[required(), email()]}
        />
        <div className="mb-l">
          <GooglePlacesAutocomplete
            source="address"
            label={t('labels.address')}
          />
        </div>
        <TextInput
          source="phone"
          name="phone"
          label={t('labels.phone')}
        />
      </div>
    </SimpleForm>
  );
}

export default UserForm;
