import {
  Datagrid,
  EmailField, List, TextField, SimpleList, useTranslate, TextInput, FunctionField, AutocompleteInput, useNotify,
} from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { useQuery } from 'react-query';
// Components
import StringListField from '../../molecules/StringListField';
// Services
import CondominiumService from '../../../services/CondominiumService';
// Utils
import formatErrorMessage from '../../../utils/formatErrorMessage';

function UserList() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const t = useTranslate();
  const notify = useNotify();
  const getCondominiumsQuery = useQuery('getCondominiums', () => CondominiumService.getCondominium(), {
    onError: (err) => notify(formatErrorMessage(err), { type: 'error' }),
  });

  const getUserFilters = () => ([
    <TextInput label="labels.search" source="all" alwaysOn />,

    <AutocompleteInput
      source="condominium"
      label="labels.condominiums"
      choices={getCondominiumsQuery?.data?.data?.condominiums || []}
      optionText="entitled"
      sx={{
        width: 220,
        marginTop: '15px',
      }}
      alwaysOn
    />,
  ].filter((filter) => filter !== null)
  );

  return (
    <List
      exporter={false}
      filters={getUserFilters()}
      debounce={500}
    >
      {isSmall
        ? (
          <SimpleList
            primaryText={(record) => `${record?.firstName || ''} ${record?.lastName || ''}`}
            secondaryText={(record) => record?.email}
            tertiaryText={(record) => record?.phone}
          />
        )
        : (
          <Datagrid
            rowClick="edit"
          >
            <EmailField source="email" label={t('labels.email')} sortable={false} />
            <TextField source="firstName" label={t('labels.firstName')} sortable={false} />
            <TextField source="lastName" label={t('labels.lastName')} sortable={false} />
            <TextField source="phone" label={t('labels.phone')} sortable={false} />
            <FunctionField
              textAlign="center"
              label="labels.condominiums"
              render={(record) => <StringListField listField={record?.condominiums?.map(({ entitled }) => entitled)} />}
            />
          </Datagrid>
        )}
    </List>
  );
}

export default UserList;
