const labels = {
  id: 'Identifiant',
  email: 'e-mail',
  firstName: 'Prénom',
  lastName: 'Nom',
  phone: 'Téléphone',
  search: 'Rechercher...',
  searchAuthor: 'Auteur...',
  searchStatus: 'Statut...',
  author: 'Auteur',
  status: 'Statut',
  message: 'Message',
  reportedAt: 'Signalé le',
  publishDate: 'Date de publication',
  unPublishDate: 'Date de suppression',
  publishAt: 'Publiée le',
  isActive: 'Statut',
  promote: 'A la une',
  description: 'Texte',
  picture: 'Visuel',
  condominiums: 'Copropriétés',
  noResults: 'Aucun résultat',
  condominium: 'Copropriété',
  role: 'Rôle',
  groups: {
    occupant: 'Occupant',
    co_owner: 'Copropriétaire',
    syndic_member: 'Membre du CS',
    facilitator: 'Facilitateur',
  },
  becomeMainFacilitator: 'Devenir référent',
  mainFacilitator: 'Référent',
  all: 'Toutes',
  category: 'Catégorie',
  type: 'Type',
  demand: 'Demande',
  offer: 'Offre',
  title: 'Titre',
  date: 'Date',
  startDate: 'Date de début',
  endDate: 'Date de fin',
  address: 'Adresse',
  countAttendees: 'Participants',
  from: 'Du',
  to: 'Au',
  the: 'Le',
  fromSimple: 'de',
  toSimple: 'à',
  active: 'Actif',
  inactive: 'Inactif',
  canceled: 'Annulé',
};

export default labels;
